<div class="container">
  <div class="row justify-content-center">
    <div class="col-12 col-md-8 col-lg-6">
      <div class="forgot-password-container p-4">
        <div class="forgot-password-logo">
          <img src="/assets/img/ideta_logo.png" alt="Ideta logo" />
        </div>

        <h1 appProtocolDroid="forgot-password.title">Forgot your password?</h1>

        <div class="text-center mt-2" appProtocolDroid="forgot-password.email-request">
          Don't worry, it happens to everyone. Give us your email adress, we will send you a link to reset your password.
        </div>

        <form class="my-4 w-100 forgot-password-form-container" (ngSubmit)="resetPassword()">
          <input class="form-control m-2" type="email" name="Email" placeholder="Email..." [(ngModel)]="emailInput" #email />
          <span class="forgot-password-form-container-error m-2" *ngIf="forgotPasswordError">{{ forgotPasswordError }}</span>
          <button
            *ngIf="!status"
            type="submit"
            class="m-2 w-100 btn btn-md btn-primary forgot-password-btn"
            appProtocolDroid="forgot-password.btn-new-password"
            [disabled]="!emailInput"
          >
            Get new password
          </button>
          <i *ngIf="status === 'pending'" class="fas fa-circle-notch fa-spin mt-3"></i>
          <i *ngIf="status === 'success'" class="fas fa-check text-success mt-3"></i>
        </form>
        <div class="text-center">
          <span class="mr-2" appProtocolDroid="forgot-password.remember-password">Remember your password?</span>
          <a [routerLink]="['/login']" appProtocolDroid="forgot-password.sign-in">Sign in</a>
        </div>
      </div>
    </div>
  </div>
</div>
