import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import 'firebase/app';
import { UserCredential } from 'firebase/auth';
import { BsModalService } from 'ngx-bootstrap/modal';

import { ProductMeta } from 'ideta-library/lib/common/bot';
import { User } from 'ideta-library/lib/common/user';

import { AuthService } from '../auth/auth.service';
import { RequestsService } from '../requests/requests.service';
import { UserSessionService } from '../session/user-session.service';

import { ModalPasswordComponent, ModalPasswordOptions } from '../../components/modals/modal-password/modal-password.component';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private modalService: BsModalService,
    private db: AngularFireDatabase,
    private authService: AuthService,
    private _user: UserSessionService,
    private requestsService: RequestsService
  ) {}

  public updateUser(user: Partial<User>): Promise<boolean> {
    if (this._user.id) {
      return this.db
        .object(`/users/${this._user.id}`)
        .update(user)
        .catch(error => {
          console.error(error);
          return false;
        })
        .then(() => true);
    } else {
      console.error('Missing user id for update');
      return Promise.resolve(false);
    }
  }

  public updateEmail(email: string, cancelCallback: Function = () => {}): Promise<void> {
    return new Promise(resolve => {
      const options: ModalPasswordOptions = {
        class: `modal-dialog-centered`,
        backdrop: 'static',
        keyboard: false,
        initialState: {
          onConfirmCallback: resolve,
          onCancelCallback: cancelCallback
        }
      };
      this.modalService.show(ModalPasswordComponent, options);
    }).then(() => this.requestsService.patch<void>(`user/${this._user.id}/email`, { email }));
  }

  public updatePassword(password: string): Promise<void> {
    return this.requestsService.patch<void>(`user/${this._user.id}/password`, {
      password
    });
  }

  public async executeProviderMigration(email: string, password: string, _credentials: UserCredential, providerId: string): Promise<void> {
    // All checks concerning the email and passwords formats and the existence of users will be be done at this step.
    // If this request does not raise any errors, that means that the user is ready to be migrated.
    // Unfortunately we cannot do the next steps from server side
    await this.requestsService.post(`user/${this._user.id}/migrate-provider`, {
      email,
      password
    });

    // Double check on authentication, should not be usefull because checks are made from the migration page.
    // if (!credentials) {
    //   switch (providerId) {
    //     case 'facebook.com':
    //       credentials = await this.authService.signinWithFacebook();
    //       break;
    //     case 'google.com':
    //       credentials = await this.authService.signinWithGoogle();
    //       break;
    //   }
    // }
    // if (!credentials) throw { error: { message: 'You must login again to execute this action', code: '40.3.3' } };

    let authCredential = await this.authService.getCredential(email, password);
    await this.authService.linkProviderToAccount(authCredential);
    await this.authService.unlinkProviderFromAccount(providerId);

    await Promise.all([this.updateUser({ email }), this.updatePassword(password)]);
  }

  public resetPassword(code: string, email: string, password: string): Promise<void> {
    return this.requestsService.post<void>('user/password/reset', { code, email, password }, true);
  }

  // TODO: add whatsapp and its link with ngx)facebook process
  public async connectToMeta(product: ProductMeta = 'facebook'): Promise<any> {
    const payload = await this.authService.linkFbWithAuthAccount(product);
    return this.requestsService.post(`user/${this._user.id}/meta`, { payload, product });
  }

  public async disconnectFromMeta(): Promise<any> {
    return this.authService
      .unlinkProviderFromAccount('facebook.com')
      .catch(err => {
        // Ignoring no auth provider error when disconnecting
        if (err.code !== 'auth/no-such-provider') throw err;
      })
      .then(() => this.updateUser({ facebook: null, instagram: null, whatsapp: null }));
  }
}
