<div class="page-container">
  <app-reset-password [resetCode]="actionCode!" [userEmail]="userEmail" [state]="state" *ngIf="showResetPassword"> </app-reset-password>
  <div class="verify-email-container" *ngIf="showVerifyEmail && state === 'success'">
    <p class="mb-3"><i class="text-success fas fa-check"></i><span appProtocolDroid="auth-handler.success">Your email has been confirmed</span></p>
    <a [routerLink]="['/login']" appProtocolDroid="auth-handler.return-app">return to the app</a>
  </div>
  <span class="info-text" *ngIf="state === 'error'">
    <p class="text-danger" appProtocolDroid="auth-handler.expired-link">This link has expired or has already been used.</p>
    <a [routerLink]="['/login']" appProtocolDroid="auth-handler.return-app">return to the app</a>
  </span>
</div>
